import axios from 'axios';

const url = "https://api.magizhon.com/"
// const url = "https://backend.magizhon.com/"

const api = axios.create({
  baseURL: url,
});

const makeRequest = async (method, url, data = null, authenticate = true) => {
  try {
    const headers = {};
    const response = await api({
      method,
      url,
      data,
      headers,
    });

    return response.data.message;
  } catch (error) {

    return "Something Went Wrong, Please Try Again."
  }
};
export default makeRequest